import transportRequest from "./transportFuncs/transportRequest";
import transportError from "./transportFuncs/transportError";

export default {
  mixins: [transportRequest, transportError],
  data() {
    return {
      loading: false,
      scheduled: false,
    };
  },
  methods: {
    transport(
      method,
      uri,
      data,
      successCallback,
      errorCallback,
      afterCallback,
      sendBearer = true,
      controller = null
    ) {
      return this.transportRequest(method, uri, data, sendBearer, controller)
        .then((response) => {
          successCallback(response);
        })
        .catch((error) => {
          this.transportError(error, errorCallback);
        })
        .then(afterCallback);
    },
    exchange(
      store,
      data,
      uri,
      method = "GET",
      successSnack = null,
      successCallback,
      scheduleIfBusy = false,
      errorCallback = null,
      controller = null
    ) {
      if (this.loading) {
        if (scheduleIfBusy) this.scheduled = arguments;
        return;
      }
      this.loading = true;
      this.transport(
        method,
        uri,
        data,
        (response) => {
          store(response.data);
          if (successSnack) {
            this.successSnack(successSnack);
          }
          if (typeof successCallback === "function") {
            successCallback(response);
          }
        },
        (response) => {
          if (typeof errorCallback === "function") errorCallback(response);
          this.showSnack();
        },
        () => {
          this.loading = false;
          if (this.scheduled) {
            const sc = [...this.scheduled];
            this.scheduled = false;
            this.exchange(...sc);
          }
        },
        true,
        controller
      );
    },
  },
};
