import readBase64 from "./readBase64";

export default {
  mixins: [readBase64],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newData: {},
      rules: {
        required: [(v) => !!v || "Заполните поле"],
        string: [(v) => !v || v.length < 255 || "не более 255 символов"],
        string511: [(v) => !v || v.length < 511 || "не более 511 символов"],
        text: [(v) => !v || v.length < 65535 || "не более 65535 символов"],
        number: [(v) => !v || !isNaN(v) || "должно быть числом"],
      },
      valid: true,
      langs: ["ru", "en", "kk"],
    };
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        this.newData = JSON.parse(JSON.stringify(this.data));
      },
    },
  },
  methods: {
    update() {
      this.$emit("update", this.newData);
    },
    setImage(field, file) {
      this.readBase64(file).then((stringData) => {
        this.$set(this.newData, field, stringData);
      });
    },
  },
};
