<template>
  <v-app>
    <v-app-bar
      v-if="!$vuetify.breakpoint.lg && !$vuetify.breakpoint.xl"
      color="deep-purple"
      dark
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
    </v-app-bar>

    <v-navigation-drawer v-if="hasToken" v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">CRM KT</v-list-item-title>
          <v-list-item-subtitle>Административная панель</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-list dense nav>
        <v-list-item
          v-for="route in routes"
          :key="route.path"
          link
          :to="route.path"
        >
          <v-list-item-icon>
            <v-icon>{{ route.meta.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ route.meta.caption }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { routes } from "../router";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    hasToken: !!window.localStorage.getItem("token"),
  }),
  computed: {
    routes() {
      const result = [];
      routes.forEach((route) => {
        if (route.meta.caption) {
          if (!route.meta.access) {
            result.push(route);
            return;
          }
          if ((this.$store.state.ACCESS & 0b1) === 0b1) {
            result.push(route);
            return;
          }
          if (
            (route.meta.access & this.$store.state.ACCESS) ===
            route.meta.access
          )
            result.push(route);
        }
      });
      return result;
    },
  },
  created() {
    window.addEventListener("logout", () => {
      this.hasToken = false;
    });
    window.addEventListener("login", () => {
      this.hasToken = true;
    });
  },
  mounted() {
    if (document.querySelector("body").offsetWidth > 1200) {
      this.drawer = true;
    }
  },
};
</script>
<style lang="scss">
.v-main__wrap {
  background: #f1eee8;
}
</style>
