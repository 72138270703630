<template>
  <v-card class="filters-card mb-2">
    <v-card-text>
      <v-expansion-panels accordion class="mb-2" v-model="panels">
        <v-expansion-panel>
          <v-expansion-panel-header>Фильтры</v-expansion-panel-header>
          <v-expansion-panel-content>
            <table class="filters-table">
              <tr>
                <th>Статус</th>
                <td width="50%">
                  <v-select
                    v-model="localFilters.filters.statuses"
                    :items="statuses"
                    label="Один из"
                    item-text="name"
                    item-value="id"
                    multiple
                    chips
                  ></v-select>
                </td>
                <th>ID</th>
                <td width="50%">
                  <v-text-field
                    v-model="localFilters.filters.id"
                    label="Равен"
                    type="number"
                    @keydown="enterKey"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th>Регион</th>
                <td>
                  <v-select
                    v-model="localFilters.filters.regions"
                    :items="regions"
                    label="Один из"
                    item-text="name"
                    item-value="id"
                    @keydown="enterKey"
                    multiple
                    chips
                  ></v-select>
                </td>
                <th>Город</th>
                <td>
                  <v-text-field label="Содержит"></v-text-field>
                </td>
              </tr>
              <tr>
                <th>Продукт</th>
                <td>
                  <v-select
                    v-model="localFilters.filters.products"
                    :items="products"
                    label="Один из"
                    item-text="name"
                    item-value="id"
                    multiple
                    chips
                  ></v-select>
                </td>
                <th>Имя</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.name"
                    label="Содержит"
                    @keydown="enterKey"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th>Телефон</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.phone"
                    label="Содержит"
                    @keydown="enterKey"
                  />
                </td>
                <th>Email</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.email"
                    label="Содержит"
                    @keydown="enterKey"
                  />
                </td>
              </tr>
              <tr></tr>
              <tr>
                <th>Выбранный пакет</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.package"
                    label="Содержит"
                    @keydown="enterKey"
                  ></v-text-field>
                </td>
                <th>Доп. инфо</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.additional_data"
                    label="Содержит"
                    @keydown="enterKey"
                  />
                </td>
              </tr>
              <tr>
                <th>Комментарий</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.comment"
                    label="Содержит"
                    @keydown="enterKey"
                  />
                </td>
                <th>Модератор</th>
                <td>
                  <v-select
                    v-model="localFilters.filters.moderators"
                    :items="moderators"
                    label="Один из"
                    item-text="name"
                    item-value="id"
                    multiple
                    chips
                  ></v-select>
                </td>
              </tr>
              <tr>
                <th>utm_source</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.utm_source"
                    label="Содержит"
                    @keydown="enterKey"
                  ></v-text-field>
                </td>
                <th>utm_medium</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.utm_medium"
                    label="Содержит"
                    @keydown="enterKey"
                  />
                </td>
              </tr>
              <tr>
                <th>utm_campaign</th>
                <td>
                  <v-text-field
                    v-model="localFilters.filters.utm_campaign"
                    label="Содержит"
                    @keydown="enterKey"
                  />
                </td>
                <th>Источник</th>
                <td>
                  <v-select
                    v-model="localFilters.filters.sources"
                    :items="sources"
                    label="Один из"
                    item-text="value"
                    multiple
                    chips
                  ></v-select>
                </td>
              </tr>
              <tr>
                <th class="date">Создан от-до</th>
                <td>
                  <v-date-picker
                    v-model="localFilters.filters.created_at"
                    range
                  ></v-date-picker>
                </td>
                <th class="date">Изменен от-до</th>
                <td>
                  <v-date-picker
                    v-model="localFilters.filters.updated_at"
                    range
                  ></v-date-picker>
                </td>
              </tr>
            </table>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Отображаемые поля</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-btn-toggle
              v-model="localFilters.fields"
              class="filter-fields"
              multiple
              dense
              group
            >
              <v-btn
                v-for="field in fields"
                :key="field.text"
                :value="field.value"
                :disabled="field.value === 'id'"
                >{{ field.text }}</v-btn
              >
            </v-btn-toggle>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn @click="resetFilters" elevation="0" small color="red lighten-3">
        сбросить фильтры
      </v-btn>
      <v-btn @click="refresh" elevation="0" small color="primary" class="mx-2">
        обновить
      </v-btn>
      <v-btn @click="excel" elevation="0" small dark color="green darken-2">
        в Excel
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import transport from "../../mixins/transport";

export default {
  name: "Filters",
  mixins: [transport],
  props: {
    value: {
      type: Object,
      required: true,
      fields: Array,
      filters: Object,
    },
    fields: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    moderators: {
      type: Array,
      required: true,
    },
    regions: {
      type: Array,
      required: true,
    },
    sources: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localFilters: {
        fields: [...this.value.fields],
        filters: this.makeFilters(),
      },
      panels: null,
    };
  },
  watch: {
    localFilters: {
      deep: true,
      handler(value) {
        this.$emit("input", value);
      },
    },
  },
  beforeMount() {
    this.keyListeners();
  },
  methods: {
    makeFilters() {
      const filters = { ...this.value.filters };
      if (!("statuses" in filters)) filters.statuses = [];
      if (!("id" in filters)) filters.id = null;
      if (!("region" in filters)) filters.regions = [];
      if (!("products" in filters)) filters.products = [];
      if (!("name" in filters)) filters.name = null;
      if (!("phone" in filters)) filters.phone = null;
      if (!("email" in filters)) filters.email = null;
      if (!("package" in filters)) filters.package = null;
      if (!("additional_data" in filters)) filters.additional_data = null;
      if (!("comment" in filters)) filters.comment = null;
      if (!("moderators" in filters)) filters.moderators = [];
      if (!("utm_source" in filters)) filters.utm_source = null;
      if (!("utm_medium" in filters)) filters.utm_medium = null;
      if (!("utm_campaign" in filters)) filters.utm_campaign = null;
      if (!("sources" in filters)) filters.sources = [];
      if (!("created_at" in filters)) filters.created_at = null;
      if (!("updated_at" in filters)) filters.updated_at = null;
      return filters;
    },
    keyListeners() {
      window.removeEventListener("keyup", this.escapeKey);
      window.addEventListener("keyup", this.escapeKey);
    },
    escapeKey(e) {
      if (e.key === "Escape") this.closeFilters();
    },
    enterKey(e) {
      if (e.key === "Enter") this.closeFilters();
    },
    closeFilters() {
      this.panels = null;
    },
    resetFilters() {
      Object.keys(this.localFilters.filters).forEach((fName) => {
        if (Array.isArray(this.localFilters.filters[fName])) {
          this.localFilters.filters[fName] = [];
          return;
        }
        this.localFilters.filters[fName] = null;
      });
      this.closeFilters();
    },
    refresh() {
      this.panels = null;
      this.$emit("refresh");
    },
    excel() {
      this.panels = null;
      this.$emit("excel");
    },
  },
};
</script>
<style lang="scss">
.filters-card {
  .v-expansion-panel::before {
    box-shadow: none;
  }
}
.filter-fields {
  flex-wrap: wrap;
}
.filters-table {
  th {
    text-align: right;
    padding: 8px;
    width: 120px;
    &.date {
      vertical-align: top;
    }
  }
  td {
    width: 1fr;
  }
  .v-date-picker-table th {
    width: auto;
  }
}
</style>
