import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    HOST: "/api/",
    TOKEN: "",
    ACCESS: 0,
    ID: 0,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  mutations: {
    setToken() {
      this.state.TOKEN = window.localStorage.getItem("token");
    },
    setAccess() {
      this.state.ACCESS = 1 * window.localStorage.getItem("access");
    },
    setId() {
      this.state.ID = 1 * window.localStorage.getItem("id");
    },
    clearToken() {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("access");
      window.localStorage.removeItem("id");
      this.state.TOKEN = "";
      this.state.ACCESS = 0;
      this.state.ID = 0;
    },
  },
  actions: {},
  modules: {},
  strict: true,
});
