<template>
  <admin-props @save="update" :actions="actions" @delete="checkRemove">
    <v-text-field
      v-model="newData.name"
      label="Текст статуса"
      :rules="rules.required.concat(rules.string)"
      persistent-hint
    />
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Удаление</span>
        </v-card-title>
        <v-card-text>
          Вы действительно хотите удалить этот элемент? Данное действие
          необратимо!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">
            Отмена
          </v-btn>
          <v-btn color="blue darken-1" text @click="remove"> Удалить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin-props>
</template>

<script>
import AdminProps from "../utils/AdminProps.vue";
import props from "../../mixins/props";

export default {
  name: "ProductProps",
  components: { AdminProps },
  mixins: [props],
  data() {
    return {
      deleteDialog: false,
    };
  },
  computed: {
    actions() {
      const actions = [];
      if (this.data.id && this.data.id !== 1) {
        actions.push({
          action: "delete",
          title: "удалить",
          color: "red darken-4",
        });
      }
      return actions;
    },
  },
  methods: {
    checkRemove() {
      this.deleteDialog = true;
    },
    remove() {
      this.deleteDialog = false;
      this.$emit("delete", this.data);
    },
  },
};
</script>
