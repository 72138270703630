<template>
  <v-row>
    <v-col>
      <users-table
        :loading="loading"
        :users="users"
        @create="newUser"
        @edit="editUser"
      />
    </v-col>
    <v-col>
      <user-props
        v-if="activeUser"
        :user="activeUser"
        @update="update"
        @remove="remove"
      />
    </v-col>
    <snackbar v-bind.sync="snackbar" />
  </v-row>
</template>

<script>
import Snackbar from "../Snackbar.vue";
import UserProps from "../users/Props.vue";
import UsersTable from "../users/Table.vue";
import transport from "../../mixins/transport";

export default {
  name: "UsersView",
  components: { UserProps, UsersTable, Snackbar },
  mixins: [transport],
  data() {
    return {
      users: [],
      first: true,
      activeUser: null,
      emptyUser: {
        email: null,
        name: null,
      },
    };
  },
  mounted() {
    this.loadList();
  },
  methods: {
    loadList() {
      this.exchange(
        (users) => {
          this.users = users;
          if (this.first) {
            if (this.$attrs.user) {
              this.activeUser = this.users.find((user) => {
                return user.id === 1 * this.$attrs.user;
              });
            }
          }
          this.first = false;
        },
        {},
        "users"
      );
    },
    newUser() {
      this.activeUser = this.emptyUser;
    },
    editUser(user) {
      this.activeUser = user;
    },
    remove(user) {
      this.exchange(
        (data) => {
          this.users = data;
        },
        null,
        "users/remove/" + user.id,
        "post",
        null,
        () => {
          this.warningSnack("Удалено");
        }
      );
    },
    update(data) {
      this.exchange(
        (response) => {
          this.users = response.list;
          this.activeUser = response.current;
        },
        data,
        "users/save",
        "post",
        "сохранено"
      );
    },
  },
};
</script>
