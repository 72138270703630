<template>
  <v-snackbar v-model="snackbar" :timeout="5000">
    {{ error }}
  </v-snackbar>
</template>

<script>
import transport from "../../mixins/transport";

export default {
  name: "Logout",
  mixins: [transport],
  created() {
    this.$store.commit("clearToken");
    this.$router.push({ name: "Login" });
  },
};
</script>
