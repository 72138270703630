<template>
  <div id="forms">
    <splitter :show-props="showProps">
      <admin-table
        :loading="loading"
        :columns="cols"
        :data="items"
        :actions="actions"
        @read="read"
        @create="create"
        @delete="remove"
        title="Формы от FB Webhook API"
      >
      </admin-table>
      <snackbar v-bind.sync="snackbar" />
      <template #props>
        <form-props
          :data="activeItem"
          @update="update"
          @delete="removeFromProp"
        />
      </template>
    </splitter>
  </div>
</template>

<script>
import Splitter from "../utils/Splitter.vue";
import AdminTable from "../utils/AdminTable.vue";
import crud from "../../mixins/crud";
import Snackbar from "../Snackbar.vue";
import FormProps from "./Props.vue";

export default {
  name: "FormsPage",
  components: { FormProps, AdminTable, Splitter, Snackbar },
  mixins: [crud],
  data() {
    return {
      cols: [
        {
          text: "#",
          align: "start",
          value: "id",
        },
        {
          text: "название",
          align: "start",
          value: "name",
        },
        {
          text: "webhook id",
          align: "start",
          value: "webhook_id",
        },
      ],
      actions: [
        {
          standard: true,
          action: "edit",
        },
        {
          standard: true,
          action: "remove",
        },
      ],
      emptyItem: {
        webhook_id: null,
        product_id: null,
        preset: null,
      },
      prefix: "forms",
    };
  },
};
</script>
