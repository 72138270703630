<template>
  <p>
    Похоже, у вас нет права доступа ни к одному разделу в админ.панели.
    Обратитесь к администратору для корректной настройки доступа
  </p>
</template>

<script>
export default {
  name: "Page",
};
</script>
