<template>
  <v-snackbar v-model="localShow" :timeout="timeout" @input="toggle">
    <p v-html="brText" class="ma-0" />
    <template #action="{ attrs }">
      <v-btn
        :color="color"
        text
        v-bind="attrs"
        @click="$emit('update:show', false)"
      >
        Закрыть
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snackbar",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    timeout: {
      type: Number,
      default: 3000,
    },
    color: {
      type: String,
      default: "blue",
    },
    text: {
      type: String,
      default: "",
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localShow: this.show,
    };
  },
  computed: {
    brText() {
      if (this.html) return this.text;
      return this.htmlEncode(this.text).replaceAll("\n", "<br>\n");
    },
  },
  watch: {
    show() {
      this.localShow = this.show;
    },
  },
  methods: {
    toggle(value) {
      this.$emit("update:show", value);
    },
    htmlEncode(string) {
      return string.replace(
        /[\u00A0-\u9999<>&]/gim,
        (i) => "&#" + i.charCodeAt(0) + ";"
      );
    },
  },
};
</script>
