<template>
  <splitter :show-props="showProps">
    <admin-table
      :loading="loading"
      :columns="cols"
      :data="items"
      :actions="actions"
      keep-header
      @read="read"
      @create="create"
      @delete="remove"
      title="Продукты"
    />
    <snackbar v-bind.sync="snackbar" />
    <template #props>
      <product-props
        :data="activeItem"
        @update="update"
        @delete="removeFromProp"
      />
    </template>
  </splitter>
</template>

<script>
import Splitter from "../utils/Splitter.vue";
import AdminTable from "../utils/AdminTable.vue";
import crud from "../../mixins/crud";
import Snackbar from "../Snackbar.vue";
import ProductProps from "./Props.vue";
export default {
  name: "ProductsPage",
  components: { ProductProps, AdminTable, Splitter, Snackbar },
  mixins: [crud],
  data() {
    return {
      cols: [
        {
          text: "#",
          align: "start",
          value: "id",
        },
        {
          text: "ключ",
          align: "start",
          value: "slug",
        },
        {
          text: "название",
          align: "start",
          value: "name",
        },
      ],
      actions: [
        {
          standard: true,
          action: "edit",
        },
        {
          standard: true,
          action: "remove",
        },
      ],
      emptyItem: {
        slug: null,
        name: null,
      },
      prefix: "products",
    };
  },
};
</script>
