<template>
  <admin-props @save="update" :actions="actions" @delete="checkRemove">
    <v-text-field
      v-model="newData.name"
      label="Название региона"
      :rules="rules.required.concat(rules.string)"
    />
    <v-textarea
      v-if="data.id !== 1"
      v-model="computedCities"
      label="Города для определения региона"
    />
    <p v-if="data.id !== 1">
      Укажите через запятую города, относящиеся к данному региону. Используйте
      различные варианты названия населенных пунктов для максимального охвата:
      старые и новые названия, варианты на обоих языках. При получении данных с
      форм поле "город" будет сравниваться с этими вариантами для определения
      региона.
    </p>
    <p v-else>
      Этот регион будет выбран, если город не был указан или не был найден в
      сетке городов прочих регионов
    </p>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Удаление</span>
        </v-card-title>
        <v-card-text>
          Вы действительно хотите удалить этот элемент? Данное действие
          необратимо!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">
            Отмена
          </v-btn>
          <v-btn color="blue darken-1" text @click="remove"> Удалить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin-props>
</template>

<script>
import AdminProps from "../utils/AdminProps.vue";
import props from "../../mixins/props";

export default {
  name: "RegionProps",
  components: { AdminProps },
  mixins: [props],
  data() {
    return {
      deleteDialog: false,
    };
  },
  computed: {
    actions() {
      const actions = [];
      if (this.data.id && this.data.id !== 1) {
        actions.push({
          action: "delete",
          title: "удалить",
          color: "red darken-4",
        });
      }
      return actions;
    },
    computedCities: {
      get() {
        return this.newData.cities.join(", ");
      },
      set(value) {
        this.newData.cities = value.replaceAll(/ *, */gi, ", ").split(", ");
      },
    },
  },
  methods: {
    checkRemove() {
      this.deleteDialog = true;
    },
    remove() {
      this.deleteDialog = false;
      this.$emit("delete", this.data);
    },
  },
};
</script>
