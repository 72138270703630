<template>
  <v-card :loading="loading">
    <v-card-text>
      <v-data-table
        class="requests-table"
        :headers="headers"
        :items="requests"
        :footer-props="footerOptions"
        :options="options"
        :server-items-length="total"
        v-on:update:options="syncOptions"
        @click:row="open"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "RequestsTable",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    requests: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      footerOptions: {
        "items-per-page-options": [10, 20, 50],
      },
    };
  },
  methods: {
    syncOptions(data) {
      this.$emit("update:options", data);
    },
    open(item) {
      this.$emit("open", item);
    },
  },
};
</script>
<style lang="scss">
.requests-table {
  td {
    cursor: pointer;
  }
}
</style>
