<template>
  <v-card class="props">
    <v-card-title>
      <v-tabs v-model="tab" fixed-tabs>
        <v-tabs-slider />
        <v-tab>Данные</v-tab>
        <v-tab>Доступ</v-tab>
      </v-tabs>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-tabs-items v-model="tab">
          <v-tab-item eager>
            <v-text-field
              v-model="newData.email"
              label="email"
              :rules="rules.req"
              type="email"
            />
            <v-text-field
              v-model="newData.name"
              label="Имя"
              :rules="rules.req"
            />
            <v-text-field
              v-model="newData.password"
              label="Пароль"
              hint="Если новый пользователь или надо сменить"
              persistent-hint
              :rules="isNew ? rules.reqPass : rules.pass"
              type="password"
            />
            <v-text-field
              v-model="newData.password_confirmation"
              label="Пароль (ещё раз)"
              hint="Если новый пользователь или надо сменить"
              persistent-hint
              :rules="isNew ? rules.reqPass : rules.pass"
              type="password"
            />
          </v-tab-item>
          <v-tab-item>
            <v-checkbox
              v-for="label in Object.keys(keys)"
              :key="label"
              v-model="accessArray"
              :label="label"
              :value="label"
            ></v-checkbox>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
      <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Удаление</span>
          </v-card-title>
          <v-card-text>
            Вы действительно хотите удалить пользователя? Данное действие
            необратимо!
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteDialog = false">
              Отмена
            </v-btn>
            <v-btn color="blue darken-1" text @click="remove"> Удалить </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="light-green darken-3" @click="save"> Сохранить</v-btn>
      <v-btn text color="deep-orange" @click="checkRemove"> Удалить</v-btn>
    </v-card-actions>
    <snackbar v-bind.sync="snackbar" />
  </v-card>
</template>

<script>
import Snackbar from "../Snackbar.vue";
import snackbarError from "../../mixins/snackbarError";

export default {
  name: "UserProps",
  components: { Snackbar },
  mixins: [snackbarError],
  props: {
    user: {
      type: Object,
      required: true,
      email: String,
      name: String,
    },
  },
  data() {
    return {
      valid: true,
      tab: 0,
      deleteDialog: false,
      newData: {
        email: null,
        name: null,
        password: null,
        password_confirmation: null,
        access: 0,
      },
      rules: {
        req: [
          (v) => !!v || "Заполните поле",
          (v) => !v || v.length < 255 || "не более 255 символов",
        ],
        pass: [
          (v) => !v || v.length > 5 || "не менее 6 символов",
          (v) => !v || v.length < 255 || "не более 255 символов",
        ],
        reqPass: [
          (v) => !!v || "Заполните поле",
          (v) => !v || v.length > 5 || "не менее 6 символов",
          (v) => !v || v.length < 255 || "не более 255 символов",
        ],
      },
      keys: {
        "Супер-админ": 0b1,
        "Работа с заявками": 0b10,
        "Управление продуктами": 0b100,
        "Управление регионами": 0b1000,
        "Управление статусами": 0b10000,
        "Специальный (видны только тестовые заявки)": 0b1000000,
      },
      accessArray: [],
    };
  },
  computed: {
    isNew() {
      return !this.user.id;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.newData = Object.assign(
          {
            password: null,
            password_confirmation: null,
          },
          this.user
        );
        this.refreshAA();
      },
    },
    accessArray(val) {
      this.updateAA(val);
    },
  },
  methods: {
    refreshAA() {
      const result = [];
      Object.keys(this.keys).forEach((label) => {
        const key = this.keys[label];
        if ((key & this.newData.access) === key) result.push(label);
      });
      this.accessArray = result;
    },
    updateAA(value) {
      let access = 0;
      value.forEach((label) => {
        access |= this.keys[label];
      });
      this.newData.access = access;
    },
    save() {
      this.$refs.form.validate();
      if (!this.valid) return;
      this.$emit("update", this.newData);
    },
    checkRemove() {
      this.deleteDialog = true;
    },
    remove() {
      this.$emit("remove", this.user);
      this.deleteDialog = false;
    },
  },
};
</script>
