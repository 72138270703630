<template>
  <v-card elevation="1" class="mx-auto" width="400px">
    <v-card-title>Вход</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          v-model="formData.email"
          :rules="rules.email"
          label="Логин"
          required
        />

        <v-text-field
          v-model="formData.password"
          :rules="rules.password"
          type="password"
          label="Пароль"
          required
        />
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-btn class="ml-auto" text color="primary" @click="submit">
        Войти
      </v-btn>
    </v-card-actions>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout">
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar.show = false">
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import transport from "../../mixins/transport";
import snackbarError from "../../mixins/snackbarError";

export default {
  name: "Login",
  mixins: [transport, snackbarError],
  data: () => ({
    valid: true,
    formData: {
      email: "",
      password: "",
    },
    rules: {
      email: [
        (v) => !!v || "Введите логин",
        (v) =>
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            v
          ) || "Логин должен быть в формате e-mail",
      ],
      password: [(v) => !!v || "Введите пароль"],
    },
    loading: false,
  }),
  created() {
    if (this.$store.state.TOKEN) {
      this.$router.push({ name: "Pages" });
    }
  },
  methods: {
    submit() {
      if (this.Loading) return;
      this.loading = true;
      this.$refs.form.validate();
      if (!this.valid) return;

      this.transport(
        "POST",
        "login",
        this.formData,
        (response) => {
          window.localStorage.setItem("token", response.data.token);
          window.localStorage.setItem("access", response.data.access);
          window.localStorage.setItem("id", response.data.id);
          this.$store.commit("setToken");
          this.$store.commit("setAccess");
          this.$store.commit("setId");
          this.$router.push("/");
        },
        this.showSnack,
        () => {
          this.loading = false;
        },
        false
      );
    },
  },
};
</script>
