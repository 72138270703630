<template>
  <div id="statuses">
    <splitter :show-props="showProps">
      <admin-table
        :loading="loading"
        :columns="cols"
        :data="items"
        :actions="actions"
        @read="read"
        @create="create"
        @delete="remove"
        title="Статусы"
      />
      <snackbar v-bind.sync="snackbar" />
      <template #props>
        <statuses-props
          :data="activeItem"
          @update="update"
          @delete="removeFromProp"
        />
      </template>
    </splitter>
  </div>
</template>

<script>
import Splitter from "../utils/Splitter.vue";
import AdminTable from "../utils/AdminTable.vue";
import crud from "../../mixins/crud";
import Snackbar from "../Snackbar.vue";
import StatusesProps from "./Props.vue";
export default {
  name: "StatusesPage",
  components: { StatusesProps, AdminTable, Splitter, Snackbar },
  mixins: [crud],
  data() {
    return {
      cols: [
        {
          text: "#",
          align: "start",
          value: "id",
        },
        {
          text: "название",
          align: "start",
          value: "name",
        },
      ],
      actions: [
        {
          standard: true,
          action: "edit",
        },
        {
          standard: true,
          action: "remove",
        },
      ],
      emptyItem: {
        name: null,
      },
      prefix: "statuses",
    };
  },
};
</script>
<style lang="scss">
#statuses tr:first-child button.mdi-delete {
  display: none;
}
</style>
