import snackbarError from "../snackbarError";

export default {
  mixins: [snackbarError],
  data: () => ({
    error: "",
    messages: {},
    fieldsNames: {},
  }),
  methods: {
    transportError(error, errorCallback) {
      // if (error.code === "ERR_CANCELED") return;
      console.error(error);
      if (!error.response) {
        this.error = "Непредвиденная ошибка";
        return;
      }

      const status = +error.response.status;

      if (status > 400 && status < 500) {
        this.error = status + " Непредвиденная ошибка";
      }

      switch (status) {
        case 400:
          this.error = status + " Ошибка клиента";
          break;
        case 401:
        case 403:
          this.$router.push({ name: "Logout" });
          break;
        case 404:
          this.error = status + " Узел не найден";
          break;
        case 422:
          this.error = status + " Ошибка валидации";
          break;
        case 429:
          this.error = status + " Слишком много запросов на сервер";
      }

      if (status >= 500) {
        this.error = status + " Ошибка сервера";
      }

      if (error.response.data.message) {
        this.error = status + " " + error.response.data.message;
      }

      if (error.response.data.errors) {
        const errorArr = [];
        const vObj = {};
        let msg;
        Object.keys(error.response.data.errors).forEach((key) => {
          this.messages[key] = "";
          msg = this.fieldsNames[key] ?? key;
          vObj[msg] = error.response.data.errors[key].join(", ");
          for (let i = 0; i < error.response.data.errors[key].length; i++) {
            msg += ": ";
            msg += error.response.data.errors[key][i];
            errorArr.push(msg);
            this.messages[key] += error.response.data.errors[key][i];
            if (i !== error.response.data.errors[key].length - 1) {
              this.messages[key] += " ";
            }
          }
        });
        this.error = errorArr.join("\n");
        this.validationSnack(vObj);
        return;
      }
      if (typeof errorCallback === "function") {
        errorCallback(error.response);
      } else this.showSnack();
    },
  },
};
