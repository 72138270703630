<template>
  <v-card :loading="loading" class="adminTable">
    <v-card-text>
      <v-data-table
        :items="data"
        item-key="id"
        :headers="compiledCols"
        :disable-pagination="!pagination"
        :hide-default-header="!keepHeader"
        :items-per-page="15"
        :dense="dense"
      >
        <template v-slot:top>
          <v-toolbar flat v-if="title || canCreate">
            <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
            <v-spacer v-if="canCreate" />
            <v-btn
              v-if="canCreate"
              color="primary"
              dark
              small
              class="mb-2"
              @click="create"
            >
              Добавить
            </v-btn>
          </v-toolbar>
        </template>
        <template v-if="showActions" v-slot:item.actions="{ item, index }">
          <template v-for="action in actions">
            <v-icon
              v-if="isAction(action, 'edit')"
              small
              class="mr-2"
              title="редактировать"
              @click="read(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAction(action, 'remove')"
              title="удалить"
              small
              @click="checkRemove(item)"
            >
              mdi-delete
            </v-icon>
            <template v-else-if="isAction(action, 'move')">
              <v-icon
                small
                class="buttonUp"
                title="поставить раньше"
                @click="move(item, 'up')"
              >
                mdi-arrow-up-thin-circle-outline
              </v-icon>
              <v-icon
                small
                class="buttonDown mr-2"
                title="поставить позже"
                @click="move(item, 'down')"
              >
                mdi-arrow-down-thin-circle-outline
              </v-icon>
            </template>
            <v-icon
              v-else-if="isAction(action, 'toggle') && !item.show"
              color="secondary"
              title="скрыто (нажмите, чтобы показывать)"
              small
              @click="toggle(item)"
              >mdi-eye-off
            </v-icon>
            <v-icon
              v-else-if="isAction(action, 'toggle') && item.show"
              color="light-blue"
              title="показывается на сайте (нажмите, чтобы скрыть)"
              small
              @click="toggle(item)"
              >mdi-eye
            </v-icon>
            <v-icon
              v-else
              :title="action.title"
              small
              @click="action.action(item)"
              >mdi-eye
            </v-icon>
          </template>
        </template>
        <template
          v-slot:[htmlCell(field)]="{ item }"
          v-for="field in htmlFields"
        >
          <span v-html="item[field]" />
        </template>
      </v-data-table>
      <v-dialog v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Удаление</span>
          </v-card-title>
          <v-card-text>
            Вы действительно хотите удалить этот элемент? Данное действие
            необратимо!
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteDialog = false">
              Отмена
            </v-btn>
            <v-btn color="blue darken-1" text @click="remove"> Удалить </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<!--suppress JSUnusedGlobalSymbols -->
<script>
export default {
  name: "AdminTable",
  props: {
    title: {
      type: String,
      default: null,
    },
    canCreate: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    keepHeader: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default() {
        return [
          {
            standard: true,
            action: "edit",
          },
        ];
      },
    },
    htmlFields: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      compiledCols: [],
      deleteItem: null,
      deleteDialog: false,
    };
  },
  watch: {
    columns: {
      immediate: true,
      handler(cols) {
        this.compiledCols = [...cols];
        if (!this.showActions) return;
        this.compiledCols.push({
          text: "действия",
          value: "actions",
          align: "end",
          sortable: false,
        });
      },
    },
  },
  methods: {
    htmlCell(field) {
      return `item.${field}`;
    },
    isAction(action, suppose) {
      return action.standard && action.action === suppose;
    },
    checkRemove(element) {
      this.deleteItem = element;
      this.deleteDialog = true;
    },
    create() {
      this.$emit("create");
    },
    read(element) {
      this.$emit("read", element);
    },
    remove() {
      this.deleteDialog = false;
      this.$emit("delete", this.deleteItem);
    },
    move(element, dir) {
      this.$emit("move", element, dir);
    },
    toggle(element) {
      this.$emit("toggle", element);
    },
  },
};
</script>

<style lang="scss">
.adminTable {
  tr:first-child .buttonUp,
  tr:last-child .buttonDown {
    display: none;
    pointer-events: none;
  }
}
</style>
