<template>
  <div id="regions">
    <splitter :show-props="showProps">
      <admin-table
        :loading="loading"
        :columns="cols"
        :data="items"
        :actions="actions"
        keep-header
        @read="read"
        @create="create"
        @delete="remove"
        title="Регионы"
      >
      </admin-table>
      <snackbar v-bind.sync="snackbar" />
      <template #props>
        <region-props
          :data="activeItem"
          @update="update"
          @delete="removeFromProp"
        />
      </template>
    </splitter>
  </div>
</template>

<script>
import Splitter from "../utils/Splitter.vue";
import AdminTable from "../utils/AdminTable.vue";
import crud from "../../mixins/crud";
import Snackbar from "../Snackbar.vue";
import RegionProps from "./Props.vue";

export default {
  name: "RegionsPage",
  components: { RegionProps, AdminTable, Splitter, Snackbar },
  mixins: [crud],
  data() {
    return {
      cols: [
        {
          text: "#",
          align: "start",
          value: "id",
        },
        {
          text: "название",
          align: "start",
          value: "name",
        },
        {
          text: "Города",
          align: "start",
          value: "tableCities",
        },
      ],
      actions: [
        {
          standard: true,
          action: "edit",
        },
        {
          standard: true,
          action: "remove",
        },
      ],
      emptyItem: {
        slug: null,
        name: null,
      },
      prefix: "regions",
    };
  },
  watch: {
    items() {
      this.items.forEach((item) => {
        item.tableCities = item.cities.join(", ");
      });
    },
  },
};
</script>
<style lang="scss">
#regions tr:first-child button.mdi-delete {
  display: none;
}
</style>
