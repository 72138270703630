import transport from "./transport";

export default {
  mixins: [transport],
  data() {
    return {
      items: [],
      activeItem: null,
      emptyItem: {},
      prefix: "",
      filters: {},
    };
  },
  computed: {
    showProps() {
      return !!this.activeItem;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.exchange(
        (items) => {
          this.items = items;
        },
        this.filters,
        this.prefix + "/"
      );
    },
    create() {
      this.activeItem = this.emptyItem;
    },
    read(item) {
      this.activeItem = item;
    },
    update(data) {
      if (!this.isEmptyObject(this.filters)) {
        data.__filters = this.filters;
      }
      return new Promise((resolve, reject) => {
        this.exchange(
          (data) => {
            this.items = data.list;
            this.activeItem = data.active;
            resolve(data.active);
          },
          data,
          this.prefix + "/save",
          "POST",
          "Сохранено",
          null,
          false,
          reject
        );
      });
    },
    remove(item) {
      this.exchange(
        (items) => {
          this.items = items;
        },
        this.filters,
        this.prefix + "/delete/" + item.id,
        "post",
        null,
        () => {
          this.warningSnack("Удалено");
        }
      );
    },
    removeFromProp(item) {
      this.remove(item);
      this.activeItem = null;
    },
    move(item, dir) {
      this.exchange(
        (items) => {
          this.items = items;
        },
        this.filters,
        this.prefix + "/move/" + item.id + "/" + dir,
        "post"
      );
    },
    toggle(item) {
      this.exchange(
        (items) => {
          this.items = items;
        },
        this.filters,
        this.prefix + "/toggle/" + item.id,
        "post"
      );
    },
    isEmptyObject(obj) {
      if (!obj) return true;
      return (
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      );
    },
  },
};
