<template>
  <v-dialog v-model="opened" max-width="1000px" scrollable>
    <v-card v-if="data">
      <v-card-title>Заявка #{{ data.id ?? "" }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="form-modal">
        <v-simple-table class="form-table">
          <template v-slot:default>
            <tr>
              <th>Имя</th>
              <td>{{ data.name }}</td>
              <th>Email</th>
              <td>{{ data.email }}</td>
              <th>Телефон</th>
              <td>{{ data.phone }}</td>
            </tr>
            <tr>
              <th>Продукт</th>
              <td>{{ data.product.name }}</td>
              <th>Город</th>
              <td>{{ data.city }}</td>
              <th>Регион</th>
              <td>{{ data.region.name }}</td>
            </tr>
            <tr>
              <th>Выбранный пакет</th>
              <td>{{ data.selected_package }}</td>
              <th>Доп. инфо</th>
              <td>{{ data.additional_data }}</td>
              <th>Источник</th>
              <td>{{ data.source }}</td>
            </tr>
            <tr>
              <th>utm_source</th>
              <td>{{ data.utm_source }}</td>
              <th>utm_medium</th>
              <td>{{ data.utm_medium }}</td>
              <th>utm_campaign</th>
              <td>{{ data.utm_campaign }}</td>
            </tr>
            <tr v-if="canSave">
              <th>Модератор</th>
              <td>{{ moderatorName }}</td>
              <th class="select-th">Статус</th>
              <td colspan="3">
                <v-select
                  :items="statuses"
                  item-text="name"
                  item-value="id"
                  v-model="data.status_id"
                />
              </td>
            </tr>
            <tr v-if="canSave">
              <th>Комментарий</th>
              <td colspan="5">
                <v-textarea v-model="data.comment" solo no-resize />
              </td>
            </tr>
            <tr v-else>
              <th>Модератор</th>
              <td>{{ moderatorName }}</td>
              <th>Статус</th>
              <td>{{ data.status.name }}</td>
              <th>Комментарий</th>
              <td>{{ data.comment }}</td>
            </tr>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="blue darken-1" text @click="close"> Закрыть </v-btn>
        <v-btn v-if="canSave" color="green darken-1" text @click="save">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RequestsForm",
  props: {
    active: {
      type: Object,
      default: null,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      data: this.active ? { ...this.active } : null,
    };
  },
  computed: {
    opened: {
      get() {
        return !!this.active;
      },
      set(val) {
        if (!val) this.close();
      },
    },
    canSave() {
      console.log(this.$store.state.ID);
      console.log(this.$store.state.ACCESS);
      if (!this.active.moderator_id) return true;
      return this.$store.state.ID === this.active.moderator_id;
    },
    moderatorName() {
      if (this.active.moderator) return this.active.moderator.name;
      return "<не назначен>";
    },
  },

  watch: {
    active() {
      this.data = this.active ? { ...this.active } : null;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.$emit("save", this.data);
    },
  },
};
</script>
<style lang="scss">
.form-table {
  td,
  th {
    padding: 8px;
    vertical-align: top;
    &.select-th {
      vertical-align: middle;
    }
  }
  th {
    text-align: right;
  }
}
@media (min-width: 1000px) {
  .form-modal {
    .v-data-table__wrapper {
      overflow-x: hidden;
    }
    .v-select.v-text-field:not(.v-text-field--single-line) input {
      display: none;
    }
  }
}
</style>
