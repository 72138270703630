import axios from "axios";

export default {
  computed: {
    token() {
      return window.localStorage.getItem("token");
    },
  },
  methods: {
    transportRequest(
      method,
      uri,
      data = {},
      sendBearer = true,
      controller = null
    ) {
      const headers = { "X-Requested-With": "XMLHttpRequest" };
      if (sendBearer) headers.Authorization = `Bearer ${this.token}`;
      this.messages = {};
      const url = uri.substr(0, 4) === "http" ? uri : "/api/" + uri;

      const axiosProps = {
        method,
        url,
        headers,
        data,
      };

      if (controller) axiosProps.signal = controller.signal;

      if (method.toLowerCase() === "get") {
        axiosProps.params = data;
      }

      return axios(axiosProps);
    },
  },
};
