<template>
  <div id="requestsPage">
    <filters
      v-model="filters"
      :fields="fields"
      :products="products"
      :statuses="statuses"
      :moderators="moderators"
      :regions="regions"
      :sources="sources"
      @refresh="reload"
      @excel="excel"
    />
    <requests-table
      :headers="enabledHeaders"
      :loading="loading"
      :requests="requests"
      :total="total"
      :options.sync="options"
      @open="open"
    />
    <requests-form
      :active="active"
      :statuses="statuses"
      @close="close"
      @save="save"
    />
    <snackbar v-bind.sync="snackbar" />
  </div>
</template>

<script>
import Filters from "./Filters.vue";
import RequestsTable from "./Table.vue";
import Snackbar from "../Snackbar.vue";
import transport from "../../mixins/transport";
import RequestsForm from "./Form.vue";

export default {
  name: "RequestsPage",
  components: { RequestsForm, RequestsTable, Filters, Snackbar },
  mixins: [transport],
  data() {
    return {
      filters: {
        fields: [
          "id",
          "name",
          "city",
          "product.name",
          "region.name",
          "status.name",
          "moderator.name",
          "created_at",
        ],
        filters: {},
      },
      headers: [
        {
          text: "#",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Имя",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Телефон",
          align: "start",
          sortable: true,
          value: "phone",
        },
        {
          text: "Город",
          align: "start",
          sortable: true,
          value: "city",
        },
        {
          text: "Продукт",
          align: "start",
          sortable: true,
          value: "product.name",
        },
        {
          text: "Регион",
          align: "start",
          sortable: true,
          value: "region.name",
        },
        {
          text: "Выбранный пакет",
          align: "start",
          sortable: true,
          value: "selected_package",
        },
        {
          text: "Доп. инфо",
          align: "start",
          sortable: true,
          value: "additional_data",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status.name",
        },
        {
          text: "Комментарий",
          align: "start",
          sortable: true,
          value: "comment",
        },
        {
          text: "Модератор",
          align: "start",
          sortable: true,
          value: "moderator.name",
        },
        {
          text: "utm_source",
          align: "start",
          sortable: true,
          value: "utm_source",
        },
        {
          text: "utm_medium",
          align: "start",
          sortable: true,
          value: "utm_medium",
        },
        {
          text: "utm_campaign",
          align: "start",
          sortable: true,
          value: "utm_campaign",
        },
        {
          text: "Источник",
          align: "start",
          sortable: true,
          value: "source",
        },
        {
          text: "Создан",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Изменен",
          align: "start",
          sortable: true,
          value: "updated_at",
        },
      ],
      requests: [],
      options: {
        itemsPerPage: 20,
      },
      active: null,
      total: 0,
      transportController: new AbortController(),
      timeout: null,
      products: [],
      statuses: [],
      moderators: [],
      regions: [],
      sources: [
        { value: "форма на сайте" },
        { value: "форма в соц. сетях" },
        { value: "Spreadsheet" },
      ],
    };
  },
  computed: {
    enabledHeaders() {
      const result = [];
      this.headers.forEach((header) => {
        if (this.filters.fields.includes(header.value)) result.push(header);
      });
      return result;
    },
    fields() {
      const result = [];
      this.headers.forEach((header) => {
        result.push({ value: header.value, text: header.text });
      });
      return result;
    },
  },
  watch: {
    options: "load",
    "filters.filters": {
      deep: true,
      handler: "load",
    },
  },
  beforeMount() {
    this.loadLists();
  },
  methods: {
    loadLists() {
      this.exchange(
        (data) => {
          this.products = data.products;
          this.statuses = data.statuses;
          this.moderators = data.moderators;
          this.regions = data.regions;
        },
        null,
        "requests/lists"
      );
    },
    load() {
      if (this.loading) this.transportController.abort();
      this.transportController = new AbortController();
      this.loading = false;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.reload, 500);
    },
    reload() {
      this.sendAndGet("requests/get", this.storeResponseData);
    },
    excel() {
      this.sendAndGet(
        "requests/excel",
        (link) => {
          window.open(link, "_blank");
        },
        null,
        null,
        "POST"
      );
    },
    sendAndGet(url, storeFn, appendData, successSnack, method = "POST") {
      const data = {
        options: this.options,
        filters: this.filters.filters,
        fields: this.filters.fields,
      };
      if (appendData) Object.assign(data, appendData);
      if (!successSnack) successSnack = "заявки загружены";
      this.exchange(
        storeFn,
        data,
        url,
        method,
        successSnack,
        null,
        false,
        null,
        this.transportController
      );
    },
    storeResponseData(data) {
      this.requests = data.requests;
      this.total = data.total;
      this.timeout = null;
    },
    open(item) {
      this.active = item;
    },
    close() {
      this.active = null;
    },
    save(data) {
      this.close();
      this.sendAndGet(
        "requests/set",
        this.storeResponseData,
        {
          id: data.id,
          status_id: data.status_id,
          comment: data.comment,
        },
        "сохранено"
      );
    },
  },
};
</script>
