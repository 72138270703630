<template>
  <div class="users-table">
    <v-data-table
      :items="users"
      :loading="loading"
      class="rounded mb-2 elevation-1"
      disable-pagination
      hide-default-footer
      :headers="headers"
    >
      <template #top>
        <v-toolbar flat class="rounded">
          <v-toolbar-title>Пользователи</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" dark class="mb-2" @click="create">
            Добавить
          </v-btn>
        </v-toolbar>
      </template>
      <template #[`item.status`]="{ item }">
        <v-icon
          v-if="isSuper(item)"
          color="orange darken-3"
          class="mr-2"
          title="Супер-администратор"
          small
        >
          mdi-emoticon-cool
        </v-icon>
        <v-icon
          v-else
          color="blue-grey"
          class="mr-2"
          title="администратор"
          small
        >
          mdi-emoticon-cool-outline
        </v-icon>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "UsersTable",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Имя",
          align: "start",
          value: "name",
        },
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        {
          text: "Статус",
          align: "start",
          value: "status",
          sortable: false,
        },
        { text: "Действия", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  methods: {
    create() {
      this.$emit("create");
    },
    edit(user) {
      this.$emit("edit", user);
    },
    isSuper(user) {
      return (user.access & 0b1) === 0b1;
    },
  },
};
</script>
