export default {
  methods: {
    readBase64(file) {
      return new Promise((resolve, reject) => {
        if (!file) {
          reject(new Error("no file"));
        }
        const reader = new FileReader();
        reader.onloadend = () => {
          this.$nextTick(() => {
            resolve(reader.result);
          });
        };
        reader.readAsDataURL(file);
      });
    },
  },
};
