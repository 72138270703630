export default {
  data: () => ({
    snackbar: {
      show: false,
      timeout: 3000,
      text: "",
      color: "red",
      html: false,
    },
  }),
  methods: {
    showSnack() {
      this.snackbar.text = this.error;
      this.snackbar.color = "red";
      this.snackbar.timeout = 3000;
      this.snackbar.show = true;
    },
    successSnack(text) {
      this.snackbar.text = text;
      this.snackbar.color = "green";
      this.snackbar.timeout = 2000;
      this.snackbar.show = true;
    },
    warningSnack(text) {
      this.snackbar.text = text;
      this.snackbar.color = "yellow";
      this.snackbar.timeout = 2000;
      this.snackbar.show = true;
    },
    errorSnack(text) {
      this.snackbar.text = text;
      this.snackbar.color = "red";
      this.snackbar.timeout = 3000;
      this.snackbar.show = true;
    },
    validationSnack(vObject) {
      this.snackbar.html = true;
      let text = "";
      let f = true;
      Object.keys(vObject).forEach((field) => {
        if (!f) text += "<br>\n";
        f = false;
        text += '<span class="red accent-4">' + field + "</span>: ";
        text += vObject[field];
      });
      this.snackbar.text = text;
      this.snackbar.color = "red";
      this.snackbar.timeout = 3000;
      this.snackbar.show = true;
    },
  },
};
