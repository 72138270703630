<template>
  <v-card class="admin-props">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <slot />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn v-if="saveAction" text color="light-green darken-2" @click="save">
        Сохранить
      </v-btn>
      <v-btn
        v-for="(action, id) in actions"
        :key="id"
        text
        :color="colorFor(action)"
        @click="emit(action.action)"
      >
        {{ action.title }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Snackbar from "../Snackbar.vue";
export default {
  name: "AdminProps",
  components: { Snackbar },
  props: {
    title: {
      type: String,
      default: "Свойства элемента",
    },
    saveAction: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
    noValidate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      defaults: false,
    },
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    save() {
      if (!this.noValidate) {
        this.$refs.form.validate();
        this.$emit("validate", this.valid);
        if (!this.valid) return;
      }
      this.$emit("save");
    },
    emit(action) {
      this.$emit(action);
    },
    colorFor(action) {
      if (typeof action.color !== "undefined") return action.color;
      return "blue-grey";
    },
  },
};
</script>
<style lang="scss">
.admin-props {
  .v-card__actions {
    flex-direction: column;
    align-items: flex-end;
  }
}
</style>
