<template>
  <admin-props
    :actions="actions"
    :loading="loading"
    @save="toUpdate"
    @delete="checkRemove"
  >
    <v-text-field
      v-model="newData.name"
      label="Название формы*"
      :rules="rules.required.concat(rules.string)"
      hint="Используется только для удобства в админ. панели"
    />
    <p v-if="!isNew">
      рекомендуется менять название формы при настройке данных для контроля
      работы форм
    </p>
    <v-text-field
      type="number"
      v-model="newData.webhook_id"
      label="Webhook id*"
      :rules="rules.required.concat(rules.number)"
      :disabled="!isNew"
    />
    <h3>Предопределение полей</h3>
    <p>
      Вы можете настроить автозаполение некоторых полей лида, если он получен
      через эту форму.
    </p>
    <p>
      Реальные данные формы имеют больший приоритет. Если в данных формы придет
      поле, определенное ниже, оно заменит это значение.
    </p>
    <v-select
      v-model="newData.product_id"
      :items="products"
      label="Продукт*"
      item-text="name"
      item-value="id"
      :rules="rules.required"
    />
    <v-select
      v-model="virtualPreset.region_id"
      :items="regions"
      item-text="name"
      item-value="id"
      label="Регион"
    />
    <v-select
      v-model="virtualPreset.moderator_id"
      :items="moderators"
      item-text="name"
      item-value="id"
      label="Назначить модератора"
    />
    <v-textarea v-model="virtualPreset.comment" label="Комментарий" />
    <v-text-field v-model="virtualPreset.name" label="Имя" />
    <v-text-field v-model="virtualPreset.email" label="Email" type="email" />
    <v-text-field v-model="virtualPreset.phone" label="Телефон" />
    <v-text-field v-model="virtualPreset.city" label="Город" />
    <v-text-field
      v-model="virtualPreset.selected_package"
      label="Выбранный пакет"
    />
    <v-textarea v-model="virtualPreset.additional_data" label="Доп. инфо" />
    <v-text-field v-model="virtualPreset.utm_source" label="utm_source" />
    <v-text-field v-model="virtualPreset.utm_medium" label="utm_medium" />
    <v-text-field v-model="virtualPreset.utm_campaign" label="utm_campaign" />
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Удаление</span>
        </v-card-title>
        <v-card-text>
          Вы действительно хотите удалить этот элемент? Данное действие
          необратимо!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="deleteDialog = false">
            Отмена
          </v-btn>
          <v-btn color="blue darken-1" text @click="remove"> Удалить </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </admin-props>
</template>

<script>
import AdminProps from "../utils/AdminProps.vue";
import props from "../../mixins/props";
import transport from "../../mixins/transport";

const presetFields = [
  "region_id",
  "comment",
  "moderator_id",
  "name",
  "email",
  "phone",
  "city",
  "selected_package",
  "additional_data",
  "utm_source",
  "utm_medium",
  "utm_campaign",
];

export default {
  name: "FormsProps",
  components: { AdminProps },
  mixins: [props, transport],
  data() {
    return {
      deleteDialog: false,
      products: [],
      regions: [],
      moderators: [],
      virtualPreset: {},
    };
  },
  computed: {
    isNew() {
      return !this.data.id;
    },
    actions() {
      const actions = [
        {
          action: "loadData",
          title: "Перезагрузить списки",
          color: "blue-grey",
        },
      ];
      if (!this.isNew) {
        actions.push({
          action: "delete",
          title: "удалить",
          color: "red darken-4",
        });
      }
      return actions;
    },
    computedCities: {
      get() {
        return this.newData.cities.join(", ");
      },
      set(value) {
        this.newData.cities = value.replaceAll(/ *, */gi, ", ").split(", ");
      },
    },
  },
  watch: {
    "data.preset": {
      immediate: true,
      deep: true,
      handler(updData) {
        this.virtualPreset = {};
        presetFields.forEach((possibleField) => {
          if (!updData || !updData[possibleField])
            this.virtualPreset[possibleField] = null;
          else this.virtualPreset[possibleField] = updData[possibleField];
        });
      },
    },
  },
  beforeMount() {
    this.loadData();
  },
  methods: {
    checkRemove() {
      this.deleteDialog = true;
    },
    toUpdate() {
      this.newData.preset = this.virtualPreset;
      this.update();
    },
    remove() {
      this.deleteDialog = false;
      this.$emit("delete", this.data);
    },
    loadData() {
      this.exchange(
        (data) => {
          this.products = data.products;
          this.regions = data.regions;
          this.moderators = data.moderators;
        },
        null,
        "forms/get-data"
      );
    },
  },
};
</script>
