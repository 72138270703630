<template>
  <v-row class="splitter-row">
    <v-col :cols="mainSize">
      <slot>Table</slot>
    </v-col>
    <v-col :cols="propsSize">
      <slot name="props" v-if="showProps">Props</slot>
      <p v-else>{{ noPropsText }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Splitter",
  props: {
    showProps: {
      type: Boolean,
      default: false,
    },
    noPropsText: {
      type: String,
      default: "Выберите элемент для редактирования или создайте новый",
    },
    mainSize: {
      type: Number,
      default: 7,
    },
  },
  computed: {
    propsSize() {
      return 12 - this.mainSize;
    },
  },
};
</script>
<style lang="scss">
.splitter-row {
  max-height: 100vh;
  align-items: stretch;
  flex-wrap: nowrap;
  > .col {
    overflow: auto;
  }
}
</style>
