import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

import Login from "./components/auth/Login.vue";
import Logout from "./components/auth/Logout.vue";
import EmptyPage from "./components/empty/Page.vue";
import RequestsPage from "./components/requests/Page.vue";
import ProductsPage from "./components/products/Page.vue";
import RegionsPage from "./components/regions/Page.vue";
import StatusesPage from "./components/statuses/Page.vue";
import FormsPage from "./components/forms/Page.vue";
import UsersView from "./components/users/Page.vue";

Vue.use(VueRouter);

const firstAvailable = () => {
  const access = 1 * window.localStorage.getItem("access");
  const target = routes.find((route) => {
    if (route.meta.notTarget) return false;
    if (!route.meta.access) return true;
    if ((access & 0b1) === 0b1) return true;
    return (route.meta.access & access) === route.meta.access;
  });
  return target ? target.path : "/";
};

export const routes = [
  {
    path: "/",
    redirect: firstAvailable,
    meta: {
      requiresAuth: true,
      notTarget: true,
    },
  },
  {
    path: "/requests",
    name: "Requests",
    component: RequestsPage,
    meta: {
      requiresAuth: true,
      access: 0b10,
      caption: "Заявки",
      icon: "mdi-list-box",
    },
  },
  {
    path: "/products",
    name: "Products",
    component: ProductsPage,
    meta: {
      requiresAuth: true,
      access: 0b100,
      caption: "Продукты",
      icon: "mdi-package-variant-closed",
    },
  },
  {
    path: "/regions",
    name: "Regions",
    component: RegionsPage,
    meta: {
      requiresAuth: true,
      access: 0b100,
      caption: "Регионы",
      icon: "mdi-map-marker",
    },
  },
  {
    path: "/statuses",
    name: "Statuses",
    component: StatusesPage,
    meta: {
      requiresAuth: true,
      access: 0b10000,
      caption: "Статусы",
      icon: "mdi-list-status",
    },
  },
  {
    path: "/forms",
    name: "Forms",
    component: FormsPage,
    meta: {
      requiresAuth: true,
      access: 0b100000,
      caption: "Facebook-формы",
      icon: "mdi-webhook",
    },
  },
  {
    path: "/users",
    name: "Users",
    component: UsersView,
    meta: {
      requiresAuth: true,
      access: 0b1,
      caption: "Пользователи",
      icon: "mdi-account-group",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      notTarget: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      requiresAuth: true,
      notTarget: true,
      caption: "Выход",
      icon: "mdi-logout",
    },
  },
  {
    path: "/empty",
    name: "Empty",
    component: EmptyPage,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.TOKEN) {
      next({ path: "/login" });
    } else if (to.meta.access) {
      const access = store.state.ACCESS;
      if ((access & 0b1) === 0b1) {
        next();
        return;
      }
      if ((access & to.meta.access) !== to.meta.access) {
        alert("Доступ ограничен");
        next(false);
      } else next();
    } else next();
  } else next();
});

export default router;
